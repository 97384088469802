/* ecommercetracker-frontend/src/css/Payment.css */

.payment-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.payment-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.order-summary {
  text-align: center;
  margin-bottom: 20px;
}

.order-summary p {
  font-size: 18px;
  font-weight: bold;
  color: #555;
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.qr-code p {
  margin-top: 10px;
  color: #555;
}

.qr-code button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 16px;
}

.qr-code button:hover {
  background-color: #218838;
}

.upi-link {
  text-align: center;
  margin-top: 20px;
}

.upi-link a {
  color: #007bff;
  text-decoration: none;
}

.upi-link a:hover {
  text-decoration: underline;
}

.upi-link button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 16px;
}

.upi-link button:hover {
  background-color: #0056b3;
}

/* Styling for the UpiApps component */
.container {
  margin-top: 20px;
}

.card {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

h2 {
  margin: 20px 0;
  color: #333;
}
