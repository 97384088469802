/* src/components/AppNavbar.css */



/* ProductList.css */

.product-list {
  padding: 20px;
  background-color: #f8f8f8;
}

.product-list h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2em;
  color: #333;
}

.product-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 250px;
  margin: 10px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.product-item:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.product-item h2 {
  font-size: 1.5em;
  margin: 10px 0;
  color: #555;
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.product-item p {
  color: #777;
  margin: 10px 0;
}

.product-price {
  font-size: 1.2em;
  color: #e74c3c;
  font-weight: bold;
}








/* ecommercetracker-frontend/src/AppNavbar.css */

.logo-image {
  height: 40px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
}





  /* Cart.css */
  .cart-container {
    padding: 20px;
  }
  
  .cart-list {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-item {
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cart-item span {
    margin: 0 10px;
  }
  
















/* src/components/Register.css */

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.register-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

.register-header {
  margin-bottom: 20px;
  text-align: center;
}

.register-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-check-input {
  margin-right: 10px;
}

.form-check-label {
  display: flex;
  align-items: center;
}

.terms-link {
  color: #007bff;
  text-decoration: none;
}

.terms-link:hover {
  text-decoration: underline;
}

.register-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-button:hover {
  background-color: #0056b3;
}

.register-footer {
  text-align: center;
  margin-top: 20px;
}

.login-link {
  color: #007bff;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
}


/* src/Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.login-card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  margin-bottom: 0.5rem;
}

.form-label-text {
  font-size: 1rem;
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.form-check-input {
  margin-right: 0.5rem;
}

.form-check-label {
  font-size: 0.875rem;
}

.login-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.login-button:hover {
  background-color: #0056b3;
}

.register-link {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.875rem;
}

.register-link-text {
  color: #007bff;
  text-decoration: none;
}

.register-link-text:hover {
  text-decoration: underline;
}






/* src/Profile.css */

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.profile-card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.profile-header {
  margin-bottom: 1.5rem;
}

.profile-icon {
  border-radius: 50%;
}

.profile-name {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.profile-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}

.profile-action {
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}

.profile-action:hover {
  text-decoration: underline;
}
