/* ecommercetracker-frontend/src/css/Details.css */

/* Container for the entire order now page */
.ordernow-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Product summary section */
.product-summary {
  margin-bottom: 20px;
}

.product-summary label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.product-summary select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-summary img.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-summary p {
  margin: 5px 0;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="tel"],
input[type="url"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="number"] {
  -moz-appearance: textfield; /* Remove number input arrows in Firefox */
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
}

button:hover {
  background-color: #218838;
}

/* Centering the form container */
.min-h-screen {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-green-400 {
  background-color: #d4edda;
}

.bg-white {
  background-color: #fff;
}

.rounded {
  border-radius: 4px;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-green-900 {
  color: #155724;
}
